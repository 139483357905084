/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// plyr.setup();

jQuery.fn.extend({
	animateCss: function (animationName) {
		var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
		this.addClass('animated ' + animationName).one(animationEnd, function () {
			console.log("element", $(this));
			console.log("rodzic", $(this).parent().parent());
			$(this).removeClass(animationName);
		});
	}
});

(function ($) {

	//  VOD box JS
	function randomInteger(min, max) {
		var rand = min - 0.5 + Math.random() * (max - min + 1)
		rand = Math.round(rand);
		return rand;
	}

	function ChangeImg() {
		var randomMainImg = randomInteger(1, 3);

		$('#main-image').removeClass().addClass("main-img-" + randomMainImg);
	}

	setTimeout(function () {
		(function loop() {
			var random = Math.round(Math.random() * (500 - 100)) + 100;
			setTimeout(function () {
				ChangeImg();
				loop();
			}, random);
		}());
	}, 1000);

	$('.vodbox-carousel').slick({
		infinite: false,
		useTransform: true
	});
	// End VOD box JS

	//	Counting Ecommerce JS
	if ($('body').hasClass("page-template-page-ecommerce")) {

		var boundingElement = document.querySelector(".three_img_text .three-images-wrap");
		var isInViewport = function (elemVp) {
			var bounding = elemVp.getBoundingClientRect();
			return (
				bounding.top >= 0 &&
				bounding.left >= 0 &&
				bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight - 300) &&
				bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		};

		var number = $(".three_img_text .three-images-wrap .p-text").each(function (index) {
			var thisNum = $(this).attr("id");
			var maxValue = $(this).attr('data-maxvalue');
			maxValue = parseFloat(maxValue.replace(/\s/g, ""));

			var numAnim = new CountUp(thisNum, 0, maxValue, 0, 5, {
				suffix: '<span>%</span>'
			});
			$(window).scroll(function () {
				if (isInViewport(boundingElement)) {
					if (!numAnim.error) {
						setTimeout(function () {
							numAnim.start();
						}, 3000);
					} else {
						console.error(numAnim.error);
					}
				}
			});
		});
	};
	// end counter
	//smooth scroll to anchor

	$('a[href^="#"]').click(function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') ||
			location.hostname == this.hostname) {

			var target = $(this.hash);
			target = target.length ? target : $('[id=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});
	//end anchor
	//ecommerce slider nav
	$('.ecommerce-slider-nav').slick({
		infinite: true,
		useTransform: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		nextArrow: "<a class='link link-sm link-line slick-next'><span class='next-text'>Następna</span></a>",
		customPaging: function (slider, i) {
			var thumb = $(slider.$slides[i]).data('thumb');
			var alt = $(slider.$slides[i]).data('alt');
			return '<a><img class="img-responsive" alt="' + alt + '" src="' + thumb + '"></a>';
		},
	});

	$(".ecommerce-slider-nav li a").mouseenter(function () {
		$(".ecommerce-slider-nav li a").not(this).css("transform", "scale(.9)");
		$(this).css("transform", "scale(1)");
	});
	$(".ecommerce-slider-nav li a").mouseleave(function () {
		$(".ecommerce-slider-nav li a").css("transform", "scale(.9)");
		$(".ecommerce-slider-nav .slick-active a").css("transform", "scale(1)");
	});

	// On before slide change
	$('.ecommerce-slider-nav').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		$(this).find(".slider-content").css("opacity", "0");
	});
	$('.ecommerce-slider-nav').on('afterChange', function (event, slick, currentSlide, nextSlide) {
		$(this).find(".slider-content").css("opacity", "1");

	});
	//end ecommerce slider nav
	//form labels animation
	$('.form_contact_ecommerce .form-wrap').focusin(function () {
		$(this).find("label").addClass("on-focus");
		$(this).find("label").addClass("change-color");
		$(this).find("input").addClass("color-change");
		$(this).find("textarea").addClass("color-change");
	});
	$('.form_contact_ecommerce .form-wrap').focusout(function () {
		$(this).find("label").removeClass("on-focus");
		$(this).find("label").removeClass("change-color");
		$(this).find("input").removeClass("color-change");
		$(this).find("textarea").removeClass("color-change");
	});
	$('.form_contact_ecommerce .form-wrap').change(function () {
		$(this).find("label").addClass("on-focus-change");
		$(this).find("label").addClass("change-color");
		$(this).find("input").addClass("color-change");
		$(this).find("textarea").addClass("color-change");
	});
	$('.form_contact_ecommerce input, .form_contact_ecommerce textarea').blur(function () {
		if ($(this).val().length < 1) {
			$(this).parent().siblings("label").removeClass("on-focus-change");
			$(this).parent().siblings("label").removeClass("change-color");
			$(this).removeClass("color-change");
		}
	});

	//end form labels animation

	$("img.lazy").Lazy();
	var i = plyr.setup('.plyr-video', {
		title: "Video demo",
		iconUrl: "https://cdn.plyr.io/2.0.13/plyr.svg"
	});
	$(".js-select2").select2();

	var videos = document.querySelectorAll('video.auto');

	for (var i = 0; i < videos.length; i++) {
		var video = videos[i];
		console.log(video);
		var promise = video.play();
		if (promise !== undefined) {
			promise.then(function () {
				// Autoplay started!
			}).catch(function (error) {
				// Autoplay was prevented.
				// Show a "Play" button so that user can start playback.
				$(".play-video").show();
			});
		}
	}

	var playbtns = document.querySelectorAll(".play-video");
	for (var i = 0; i < playbtns.length; i++) {
		var btn = playbtns[i];
		btn.addEventListener("click", function () {
			this.parentNode.querySelector("video").play();
			this.style.display = 'none';
		})
	}
})(jQuery);

(function ($) {
	var Sage = {
		// All pages
		'common': {
			init: function () {

				var lastScrollTop = 0,
					isLogoWhite = $('.logo-white').is(":visible");
				header = $('header.navbar'),
					logoBlack = $('a.logo.logo-black'),
					logoWhite = $('a.logo.logo-white'),
					body = $('body'),
					st = 0;

				$(window).scroll(function (event) {

					if( ! $("body").hasClass("page-template-page-ecommerce") ) {

						if ($(this).scrollTop() > $(".navbar-header").height()) {
							// animacja nav-bar
							st = $(this).scrollTop();
							if (st > lastScrollTop) {
								// downscroll
								header.css({
									'transform': 'translateY(-' + header.outerHeight() + 'px)'
								});
							} else {
								// upscroll 
								header.css({
									'transform': 'translateY(0)'
								});;
							}

							lastScrollTop = st;
						}

					}

				});

				$(window).scroll(function () {

					if( ! $("body").hasClass("page-template-page-ecommerce") ) {

						if ($(this).scrollTop() > $(".navbar-header").height()) {
							header.removeClass('header-bg-transparent-text');
							header.removeClass('header-bg-transparent');
							logoBlack.removeClass('hidden');
							logoWhite.addClass('hidden');

						} else {
							header.addClass('header-bg-transparent-text');

							if (isLogoWhite) {
								header.addClass('header-bg-transparent');
								logoWhite.removeClass('hidden');
								logoBlack.addClass('hidden');
							}

						}

					}

				});


				function animate_svg_elements() {
					$('.slick-animation-item-back-to-menu-el1').animateCss('fadeInUp');
					$('.slick-animation-item-back-to-menu-el2').animateCss('fadeInUp');
					$('.slick-animation-item-back-to-menu-el3').animateCss('fadeInUp');
					$('.slick-animation-item-back-to-menu-start').animateCss('fadeInUp');
					$('.item2 .animation-block-text').animateCss('fadeInRight');
					$('.item3 .animation-block-text').animateCss('fadeInLeft');
				}


				if($('.slick-animation').length > 0) {
					$('.slick-animation').slick({
						slidesToShow: 1,
						arrows: false,
						autoplay: true,
						autoplaySpeed: 4000,
						infinite: true,
						speed: 500,
						fade: true,
						cssEase: 'none'
					});

					$('.slick-animation-item-back-to-menu-el1').animateCss('fadeInUp');
					$('.slick-animation-item-back-to-menu-el2').animateCss('fadeInUp');
					$('.slick-animation-item-back-to-menu-el3').animateCss('fadeInUp');
					$('.slick-animation-item-back-to-menu-start').animateCss('fadeInUp');
					
					$('.slick-animation').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
						console.log('nextSlide');
						setTimeout(function () {
							$('#arrow-jedz').addClass('arrow-jedz');
							$('#arrow-zawroc').addClass('arrow-zawroc');
						}, 800);

						$('.arrow-jedz').removeClass('arrow-jedz');
						$('.arrow-zawroc').removeClass('arrow-zawroc');

						animate_svg_elements();

					});
				}

				function setCookie(cname, cvalue, exdays) {
					var d = new Date();
					d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
					var expires = "expires=" + d.toUTCString();
					document.cookie = cname + "=" + cvalue + "; " + expires;
				}

				(function ($, win) {
					$.fn.inViewport = function (cb) {
						return this.each(function (i, el) {
							function visPx() {
								var H = $(this).height(),
									r = el.getBoundingClientRect(),
									t = r.top,
									b = r.bottom;
								return cb.call(el, Math.max(0, t > 0 ? H - t - 500 : (b < H ? b : H)));
							}
							visPx();
							$(win).on("resize scroll", visPx);
						});
					};
				}(jQuery, window));

				$(".for-white-paper").inViewport(function (px) {
					if (px) {
						$(this).find(".white-paper").addClass("customfadeInUp");
					}
				});


				$('.social-share a').click(function (event) {

					if ($(this).hasClass('no-popup'))
						return;

					event.preventDefault();


					var href = $(this).attr('href');
					window.open(href, "", "width=500,height=500");
				});


				if (window.getComputedStyle(document.body).mixBlendMode !== undefined) {
					$(".letter").addClass("opacity-fix");
				}

				$(document).delegate('*[data-toggle="lightbox"]', 'click', function (event) {
					event.preventDefault();
					$(this).ekkoLightbox();
				});


				$('.scroll-to-top').on('click', function () {
					$('html, body').animate({
						scrollTop: 0
					}, 1000);
				});

				$('.scroll-to-section').on('click', function (e) {
					e.preventDefault();
					var href = $(this).attr("href");
					$('html, body').animate({
						scrollTop: $(href).offset().top
					}, 1000);
				});

				/*
							$('.write-to-us').on('click', function(){
								$('html, body').animate({
							        scrollTop: $(".contact").offset().top
							    }, 1000);
							});
				*/

				/*
							var letter = $('.contact.short').data('letter');
							$('.letter-wrapper').html('<div class="letter footer-'+letter+' scrollme animateme" data-when="span" data-from="0.75" data-to="1" data-easing="easeinout" data-translatey="50">'+letter+'</div>');
							
				*/



				function isScrolledIntoView(elem) {
					if ($(elem).length) {
						var docViewTop = $(window).scrollTop();
						var docViewBottom = docViewTop + $(window).height();

						var elemTop = $(elem).offset().top;
						var elemBottom = elemTop + $(elem).height();


						// 					console.log( elem + ' ' + ((elemBottom <= docViewBottom) && (elemTop >= docViewTop)) );

						return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
					}
				}

				var played = 0;

				$(window).scroll(function () {



					$('.letter').each(function () {
						var $this = $(this);

						var parallaxTop = $this.offset().top;
						var parallaxStart = parallaxTop * .9;

						if ($(window).scrollTop() >= parallaxStart) {
							$this.css({
								'transform': 'translateY(-' + (($(window).scrollTop() - parallaxStart) / 2) + "px)"
							});
						}
					});

					/*
									if(isScrolledIntoView('.agency-a')) {
										var parallaxTop = $('.agency-a').offset().top;
										var parallaxStart = parallaxTop * .9;
										
										if ($(this).scrollTop() >= parallaxStart) {
											$('.agency-a').css({
												'transform': 'translateY(-' + (($(this).scrollTop() - parallaxStart) / 2) + "px)"
											});
									    }	
								    }
					*/

					if (isScrolledIntoView('.animate-letters')) {
						$('.animate-letters').addClass('animate');
					}

					if (isScrolledIntoView('.background-letter')) {
						playLetters();
					}

					var st = $(this).scrollTop();
					if (st > 1400) {
						$('.floating-element').fadeIn();
					} else {
						$('.floating-element').fadeOut();
					}

				});

				var playLetters = function () {
					if (played == 0) {
						var mainLetter = $('.bigLetter'),
							mainElement = $('.background-letter'),
							words = mainElement.data('word'),
							max = words.length,
							i = 0;

						setInterval(function () {

							mainLetter.html(words[i]);

							if (i < max) {
								i++;
							} else {
								i = 0;
							}
						}, 200);
					}

					played = 1;
				}


				if ($("section").hasClass("photography")) {
					var $this = $('.buttons-wrapper button.active');
					var filter = $this.data('filter');
					var items = $('figure.element');
					//$this.addClass('active').siblings().removeClass('active');
					console.log(filter);
					if (filter == '*' || typeof $this == undefined) {
						items.fadeIn();
					} else {
						$.each(items, function () {
							if (!$(this).hasClass(filter)) {
								$(this).fadeOut();
							} else {
								$(this).fadeIn();
							}
						});
					}
				}
				
				//parallax
				$(window).ready(function() {
					if($(window).width() > 991) {
						$(".portfolio-parallax").mousemove(function(event) {
						
						var amountMovedXbg = (event.pageX * -.10 / 20);
						var amountMovedYbg = (event.pageY * -.10 / 20);
						
						$(".inner-wrap").css("transform", "matrix(1, 0, 0, 1, " + amountMovedXbg + ", " + amountMovedYbg + ")");
		// 				$(".inner-wrap").css("transform", "rotateX( " + amountMovedXbg + "deg) rotateY(" + amountMovedYbg + "deg)");
						
						var amountMovedX0 = (event.pageX * -.25 / 50);
						var amountMovedY0 = (event.pageY * -.2 / 50);
						
						$(".single-0").css("transform", "matrix(1, 0, 0, 1, " + amountMovedX0 + ", " + amountMovedY0 + ")");
						
						var amountMovedX = (event.pageX * -.5 / 50);
						var amountMovedY = (event.pageY * -.4 / 50);
						
						$(".single-1").css("transform", "matrix(1, 0, 0, 1, " + amountMovedX + ", " + amountMovedY + ")");
						
						var amountMovedX2 = (event.pageX * -1 / 50);
						var amountMovedY2 = (event.pageY * -.8 / 50);
						
						$(".single-2").css("transform", "matrix(1, 0, 0, 1, " + amountMovedX2 + ", " + amountMovedY2 + ")");
						
						var amountMovedX3 = (event.pageX * -4 / 50);
						var amountMovedY3 = (event.pageY * -3 / 50); //im mniejsza liczba do podzielenia, tym mniejszy zakres ruchu w osi y
						
						$(".single-3").css("transform", "matrix(1, 0, 0, 1, " + amountMovedX3 + ", " + amountMovedY3 + ")"); // a - scale(X) b - skew c - skew d - scale(Y) e - translate f - translate;
						
						});
					}
				});		


				$('.buttons-wrapper button').on('click', function () {
					var filter = $(this).data('filter');
					var items = $('figure.element');

					var $href = "http://www.052b.pl/fotografia/kategoria/" + $(this).html().toLowerCase();
					var $href2 = "/fotografia/kategoria/" + $(this).html().toLowerCase()
					window.history.pushState({
						"html": $href,
						"pageTitle": " Fotografia - 052B "
					}, "", $href2);


					$(this).addClass('active').siblings().removeClass('active');

					if (filter == '*') {
						items.fadeIn();
					} else {
						$.each(items, function () {
							if (!$(this).hasClass(filter)) {
								$(this).fadeOut();
							} else {
								$(this).fadeIn();
							}
						});
					}
				});


				var slick_element = $('.slick-carousel');
				slick_element.slick({
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
					dots: true,
					speed: 500,
					fade: true,
					autoplay: true,
					cssEase: 'linear',
					nextArrow: '<button class="slick-next"></button>',
					prevArrow: '<button class="slick-prev"></button>',
				});


				var slick_reference_element = $('.slick-reference-carousel');
				slick_reference_element.slick({
					infinite: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: true,
					dots: false,
					autoplaySpeed: 10000,
					speed: 200,
					fade: true,
					autoplay: true,
					cssEase: 'linear',
					nextArrow: '<button class="slick-reference-next tracking-reference-next">' + slick_reference_element.data("next") + '</button>',
					prevArrow: '<button class="slick-reference-prev tracking-reference-prev">' + slick_reference_element.data("prev") + '</button>',
				});

				slick_reference_element.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
					$(".reference-single").addClass("opacity");
				});

				slick_reference_element.on('afterChange', function (event, slick, currentSlide, nextSlide) {
					$(".reference-single").removeClass("opacity");
				});

				var currentSlide = slick_element.slick('slickCurrentSlide');
				if (currentSlide == 0) {
					$('.slick-prev').hide();
				}

				slick_element.on('afterChange', function () {
					var currentSlide = slick_element.slick('slickCurrentSlide');
					var totalSlides = slick_element.slick("getSlick").slideCount - 1;

					if (currentSlide == 0) {
						$('.slick-prev').hide();
						$('.slick-next').show();
					} else if (currentSlide == totalSlides) {
						$('.slick-next').hide();
						$('.slick-prev').show();
					}

					if (currentSlide > 0 && currentSlide < totalSlides) {
						$('.slick-prev').show();
						$('.slick-next').show();
					}
				});

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {

				$('.go-down').on('click', function () {
					$('html, body').animate({
						scrollTop: $(".we-do").offset().top
					}, 1000);
				});

			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'single_fotografia': {
			init: function () {
				$('.slick-thumbnail').each(function () {
					var $this = $(this);

					$this.css({
						'width': $this.find('img').outerWidth() + 'px !important'
					});
				});

				var $slickElement = $('.slider-for');
				var $status = $('.pagingInfo');

				$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
					//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
					var i = (currentSlide ? currentSlide : 0) + 1;
					$status.text(i + '/' + slick.slideCount);
				});

				$slickElement.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					fade: true,
					asNavFor: '.slider-nav',
					adaptiveHeight: true
				});
				$('.slider-nav').slick({
					slidesToShow: 5,
					slidesToScroll: 1,
					asNavFor: '.slider-for',
					dots: false,
					centerMode: true,
					focusOnSelect: true,
					variableWidth: true,
					arrows: false,
					infinite: false
				});

				$('.slick-prev').on('click', function () {
					$slickElement.slick('slickPrev');
				})

				$('.slick-next').on('click', function () {
					$slickElement.slick('slickNext');
				})


			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	$(function () {
		$('img.svg').each(function () {
			var $img = jQuery(this);
			var imgID = $img.attr('id');
			var imgClass = $img.attr('class');
			var imgURL = $img.attr('src');

			$.get(imgURL, function (data) {
				// Get the SVG tag, ignore the rest


				var $svg = $(data).find('svg');

				$svg.find('style').remove();

				// Add replaced image's ID to the new SVG
				if (typeof imgID !== 'undefined') {
					$svg = $svg.attr('id', imgID);
				}
				// Add replaced image's classes to the new SVG
				if (typeof imgClass !== 'undefined') {
					$svg = $svg.attr('class', imgClass + ' replaced-svg');
				}

				// Remove any invalid XML tags as per http://validator.w3.org
				$svg = $svg.removeAttr('xmlns:a');


				// Replace image with new SVG
				$img.replaceWith($svg);

			}, 'xml');
		}); // img.svg

		$('.file-cv input').change(function () {
			var $txt = $(this).parents('.file-cv').find('.txt');
			var $this = $(this);


			$txt.html($this.val() ? $this.val().replace('/C:\\fakepath\\/i', '') : 'CV')

			console.log($this);
		})


	});





})(jQuery);




var wowInit = (function ($) {
	var _public = {

		_width: function () {
			var width = $(window).width();

			if (width >= 767) {
				new WOW().init();
			}
		},

		init: function () {
			$(document).ready(_public._width);
		}
	}

	return _public;
}(jQuery)).init();





var VideoTeaser = (function ($) {
	var _private = {

		init: function () {
			var vid = $('.video-teaser-agency');

			var teaser = vid.find('.teaser').get(0);
			var video = vid.find('.video').get(0);

			vid.find('.play a').click(function (event) {
				event.preventDefault();

				video.play();

				vid.toggleClass('playing');

				video.onended = function () {
					vid.toggleClass('playing');
				};
			});
		}
	};


	var _public = {
		init: function () {
			$(document).ready(_private.init);
		}
	}
	return _public;
}(jQuery)).init();


(function ($) {
	$('#newsletter-submit').on('click', function (e) {
		e.preventDefault();

		var name = $('#name').val();
		var email = $('#email').val();

		console.log("name", name);
		console.log("email a", email);
	})

	var Forms = {
		clickedButton: false,
		isValid: true,
		init: function () {
			//helpers			
			$(document).on("focus", ".new-ui-form input, .new-ui-form textarea", Forms.onFocus);
			$(document).on("change", ".new-ui-form input, .new-ui-form textarea", Forms.checkFocus);
			$(document).on("focusout", ".new-ui-form input", Forms.onFocusOut);

			//validation	
			$(document).on("change", '.new-ui-form select', Forms.onSelectChange);
			$(document).on("keyup", '.new-ui-form input[type="text"]', Forms.onInputTextChange);
			$(document).on("change", '.new-ui-form input[type="text"]', Forms.onInputTextChange);
		},
		onSelectChange: function (e) {
			$container = $(this).data("other");
			if (!$(this).val()) {
				$("#" + $container).slideUp();
				if ($(this).hasClass("required")) {
					$msgTextEmpty = $('#msg-text-empty').data("message");
					$(this).siblings("button").addClass("not-valid");
					$("label[for='" + $(this).attr('id') + "']").siblings(".validation-message").html($msgTextEmpty);
				}

			} else {
				$(this).siblings("button").removeClass("not-valid").addClass("valid");
				$(this).removeClass("not-valid").addClass("valid");
				$("label[for='" + $(this).attr('id') + "']").siblings(".validation-message").html("");

				if ($(this).val() == "other") {
					$("#" + $container).slideDown();
				} else {
					$("#" + $container).slideUp();
				}
			}
		},

		onFocus: function () {
			var label = $('label[for="' + $(this).attr('id') + '"]');
			label.addClass("on-focus");
		},
		checkFocus: function () {

			var label = $('label[for="' + $(this).attr('id') + '"]');
			if ($(this).val()) {
				label.addClass("on-focus");
			} else {
				label.removeClass("on-focus");
			}

		},
		onFocusOut: function () {
			if (!$(this).val()) {
				var label = $('label[for="' + $(this).attr('id') + '"]');
				label.removeClass("on-focus");
			}
		},
		onInputTextChange: function () {
			$this_val = $(this).val();
			if ($(this).is('.camel-case')) {
				$(this).val($this_val.charAt(0).toUpperCase() + $this_val.slice(1));
			}

			if ($(this).is('.required')) {
				if ($this_val.length) {
					$(this).removeClass("not-valid").addClass("valid");
					$("label[for='" + $(this).attr('id') + "']").siblings(".validation-message").html("");
				} else {
					$(this).removeClass("valid").addClass("not-valid");
					$msgTextEmpty = $('#msg-text-empty').data("message");
					$("label[for='" + $(this).attr('id') + "']").siblings(".validation-message").html($msgTextEmpty);
				}
			} else {
				if ($this_val.length) {
					$(this).addClass("valid");
				} else {
					$(this).removeClass("valid");
				}
			}
		}
	};
	Forms.init();
	
})(jQuery);